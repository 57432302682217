.BtnBuy {
  @apply font-display items-center font-black uppercase flex;
  @apply p-4 !important;
}

.BtnBuy__Full {
  @apply flex w-full items-center rounded-lg py-2 md:hidden;
}

.BtnBuy__Full__Link {
  @apply font-display relative inline-flex w-full items-center justify-center rounded-lg border border-transparent text-center text-sm font-bold uppercase shadow-sm focus:outline-none focus:ring-offset-gray-800 md:focus:ring-2 md:focus:ring-offset-2;
}

.BtnBuy__Full > a,
.BtnBuy__Full__Link > a {
  @apply h-full w-full text-lg hover:no-underline;
}

.BtnBuy__wrapper_full {
  @apply flex-shrink-0 block py-0;
}

.BtnBuy__wrapper {
  @apply flex-shrink-0 hidden md:block;
}

.BtnBuy__wrapper a {
  @apply inline-flex;
}

.BtnBuy__link,
.BtnBuy__Full__Link {
  @apply p-0 !important;
}

.BtnBuy__link a,
.BtnBuy__Full__Link a {
  @apply px-4 py-2;
}

.BtnBuy__Full__Link a {
  @apply py-3;
}

.BtnBuy__link > a > span {
  @apply hidden font-black md:block;
}

.BtnBuy__Icon {
  max-width: "35px";
  max-height: "35px";
  @apply block h-8 w-8 md:mr-2 md:h-5 md:w-5;
}

.BottomBtnBuy {
  @apply fixed inset-x-0 bottom-0 z-30 bg-white md:hidden;
}

.BottomBtnBuy a {
  @apply flex items-center justify-center;
}

.BottomBtnBuy a svg {
  @apply mr-2;
}

.BottomBtnBuy__Transition_enter,
.BottomBtnBuy__Transition_leave {
  @apply transform transition duration-300 ease-in-out;
}

.BottomBtnBuy__Transition_enterFrom,
.BottomBtnBuy__Transition_leaveTo {
  @apply translate-y-full;
}

.BottomBtnBuy__Transition_enterTo,
.BottomBtnBuy__Transition_leaveFrom {
  @apply translate-y-0;
}
